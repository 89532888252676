import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import get from 'lodash.get';

import Link from 'components/Link';
import TypeIcon from 'components/TypeIcon';
import TeasePicture from 'components/TeasePicture';
import { Unibrow } from 'components/Unibrow';
import { Save } from 'components/SocialShareMenu/Save';
import { TypeIconInline } from 'components/TypeIconInline';
import { useShouldShowSource } from 'lib/Hooks/useShouldShowSource';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import { timeFrom } from 'lib/DateTime';
import { imageAltForItem } from 'lib/imageAlt';
import { shouldTargetBeBlankHelper } from 'lib/shouldTargetBeBlankHelper';

import { getSiteConfigs } from 'lib/vertical';
import { getUniformTeaseItem, isThinkModule } from 'lib/teaseUtils';
import { isBreakingNewsText } from 'lib/breakingNews';
import {
  FeatureFlagContext,
  LayoutContext,
  VerticalContext,
} from 'lib/ContextTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { PKG_SAVE, AUTHOR_FEED_TEASE_VARIANT } from 'lib/brandFeatures';

import './styles.themed.scss';
import RecipeDetails from 'components/Recipe/RecipeDetails';

const POSSIBLE_METADATA = {
  TEASE: 'showTeaseImage',
  TIMESTAMP: 'showTimestamp',
  DEK: 'showDek',
  EYEBROW: 'showEyebrow',
  BRAND: 'showBrand',
};

const renderArticleImage = ({
  additionalClasses,
  content,
  isFluidWidthPage,
  showImage,
  showSave,
  sportsFrontFF,
  tease,
  url,
  vertical,
  shouldOpenLinkInNewTab,
}) => {
  const {
    id,
    item,
    type,
  } = content;
  const teaseVariant = getFeatureConfigForBrand(AUTHOR_FEED_TEASE_VARIANT, vertical);
  const squareTease = teaseVariant || isFluidWidthPage;

  const isSportsVideoWorkflow = item?.taxonomy?.additionalTerms?.filter(({ termName }) => (
    termName?.display === 'Workflow: NBC Sports Video'
  ))?.length > 0;

  const isSportsVideo = sportsFrontFF && isSportsVideoWorkflow ? 'video' : null;

  if (!showImage || !tease) {
    return showSave ? (
      <div
        className={classNames('wide-tease-item__no-image-save', additionalClasses)}
        data-testid="wide-tease-save"
      >
        <Save
          contentId={id}
          contentType={type}
        />
      </div>
    ) : null;
  }

  return (
    <div
      className={classNames(
        'wide-tease-item__image-wrapper',
        'flex-none',
        'relative',
        additionalClasses,
      )}
    >
      <Link
        to={url}
        data-testid="wide-tease-image"
        target={shouldOpenLinkInNewTab ? '_blank' : ''}
      >
        <TeasePicture
          className="wide-tease-item__image"
          type={type}
          placeholder={false}
          retina
          responsiveFlavors={{
            s: squareTease ? AIMS_FLAVORS.FOCAL_200x200 : AIMS_FLAVORS.FOCAL_200x100,
            m: AIMS_FLAVORS.FOCAL_220x110,
            l: AIMS_FLAVORS.FOCAL_280x140,
            xl: AIMS_FLAVORS.FOCAL_260x130,
          }}
          url={tease}
          alt={imageAltForItem(content)}
          isSportsVideo={isSportsVideo}
        />
        {showSave ? (
          <Save
            contentId={id}
            contentType={type}
            options={{
              isThumbnail: true,
              offset: 's-only',
            }}
            additionalClasses="wide-tease-item__save"
          />
        ) : (
          <TypeIcon
            type={type}
            className="wide-tease-item__type-icon"
          />
        )}
      </Link>
    </div>
  );
};

const getBrand = ({
  vertical,
  showBrand,
  additionalClasses,
  isFluidWidthPage,
  HTag,
}) => {
  if (!showBrand || !vertical) {
    return null;
  }

  const brandUnibrand = {
    text: vertical,
  };

  const config = getSiteConfigs(vertical.toLowerCase());
  if (config?.siteUrl) {
    brandUnibrand.url = {
      primary: config.siteUrl,
    };
  }

  const unibrowClassNames = !isFluidWidthPage ? classNames(
    additionalClasses,
    'wide-tease-item__unibrow',
    'wide-tease-item__unibrow--brand',
  ) : additionalClasses;

  return (
    <Unibrow
      className={unibrowClassNames}
      hasDefaultTextStyle={isFluidWidthPage}
      unibrow={brandUnibrand}
      size={HTag}
    />
  );
};

const getUnibrow = ({
  additionalClasses,
  content,
  showEyebrow,
  unibrow,
  isFluidWidthPage,
  HTag,
}) => {
  if (!showEyebrow || !unibrow) {
    return null;
  }

  const moduleClass = isThinkModule(content);

  const unibrowClassNames = !isFluidWidthPage ? classNames(
    moduleClass,
    additionalClasses,
    'wide-tease-item__unibrow',
  ) : additionalClasses;

  return (
    <Unibrow
      className={unibrowClassNames}
      hasDefaultTextStyle={isFluidWidthPage}
      unibrow={unibrow}
      size={HTag}
    />
  );
};

const renderUnibrow = ({
  additionalClasses,
  content,
  dataAttributes,
  showBrand,
  showEyebrow,
  unibrow,
  vertical,
  isFluidWidthPage,
  HTag,
}) => {
  const unibrowComp = getUnibrow({
    additionalClasses,
    content,
    showEyebrow,
    unibrow,
    isFluidWidthPage,
    HTag,
  });
  const brandComp = getBrand({
    vertical,
    showBrand,
    additionalClasses,
    isFluidWidthPage,
    HTag,
  });

  if (!unibrowComp && !brandComp) {
    return null;
  }

  const separator = unibrowComp && brandComp ? (
    <div
      className={classNames(
        'wide-tease-item__unibrow-separator',
        additionalClasses,
      )}
    >
      {' '}
      /
      {' '}
    </div>
  ) : (
    ''
  );

  // if breaking news, return vanilla unibrow without brand & separator
  if (isBreakingNewsText(unibrow.text)) {
    return unibrowComp;
  }

  return (
    <div
      className="wide-tease-item__unibrow-wrapper df"
      data-testid={dataAttributes}
    >
      {brandComp}
      {separator}
      {unibrowComp}
    </div>
  );
};

/**
 * Renders the date-timestamp of the item
 * @param {string} datePublished
 * @param {boolean} hasUnibrow
 * @param {object} t
 */
const renderDateTimestamp = (datePublished, hasUnibrow, t) => (
  <div
    className={classNames('wide-tease-item__timestamp dib db-m',
    {
      ml3: hasUnibrow,
      'ml0-m': hasUnibrow,
    })}
    data-testid="wide-tease-date"
  >
    {`${t('time_ago', { time: timeFrom(datePublished) })}`}
  </div>
);

/**
 * Renders the child component of the renderUnibrowAndDate function.
 * @param {boolean} shouldEnableNewSportsFeedMobileDesign
 * @param {boolean} hasUnibrow
 * @param {boolean} hasDate
 * @param {string} datePublished
 * @param {boolean} unibrowComps
 * @param {object} t
 */
const renderUnibrowAndDateChild = (shouldEnableNewSportsFeedMobileDesign, hasUnibrow, hasDate, datePublished, unibrowComps, t) => {
  if (shouldEnableNewSportsFeedMobileDesign) {
    return (
      <>
        { hasDate && renderDateTimestamp(datePublished, hasUnibrow, t) }
        { unibrowComps }
      </>
    );
  }

  return (
    <>
      { unibrowComps }
      { hasDate && renderDateTimestamp(datePublished, hasUnibrow, t) }
    </>
  );
};

const renderUnibrowAndDate = ({
  content,
  datePublished,
  showBrand,
  showEyebrow,
  showTimestamp,
  unibrow,
  vertical,
  isFluidWidthPage,
  enableNewSportsFeedMobileDesign,
}) => {
  const { t } = useTranslation();

  const hasDate = showTimestamp && !!datePublished;
  const unibrowComps = renderUnibrow({
    additionalClasses: 'dib dn-m',
    content,
    showBrand,
    showEyebrow,
    unibrow,
    vertical,
    isFluidWidthPage,
  });
  const hasUnibrow = !!unibrowComps;

  const showEyebrowWrapper = hasUnibrow || hasDate;
  if (!showEyebrowWrapper) {
    return null;
  }

  return (
    <div
      className={
        classNames(
          'wide-tease-item__unibrow-timestamp',
          'df',
          'flex-none-m',
          {
            'dn-m': !hasDate && hasUnibrow,
          },
        )
      }
    >
      { renderUnibrowAndDateChild(enableNewSportsFeedMobileDesign, hasUnibrow, hasDate, datePublished, unibrowComps, t) }
    </div>
  );
};

const getMetadata = (path, props) => {
  const {
    content: {
      metadata,
    },
  } = props;
  return get(metadata, path, true);
};

/**
 * sourceToDisplay determines whether to display NBC Sports, or if not, default to NBC News.
 * @param {string} sourceName
 * @returns {string} sourceDisplay based off of the sourceName value
 */
const sourceToDisplay = (sourceName) => {
  const isSourceSports = sourceName === 'NBC Sports';
  const isSourceNews = sourceName === 'NBC News';

  const sourceDisplay = isSourceSports || isSourceNews;

  return sourceDisplay;
};
/**
 * WideTease component to render a wide teaser.
 *
 * @param {object} props - The properties for the WideTease component.
 * @param {boolean} [props.hideBorder=false] - Flag to hide the border.
 * @param {import('lib/CustomPropTypes/content').Content} props.content - The content to render inside the component.
 * @param {boolean} [props.isLastArticle=false] - Flag to indicate if this is the last article.
 * @param {boolean} [props.isRail=false] - Flag to indicate if this is a rail component.
 * @param {string} [props.HTag='h2'] - HTML tag to use for the header.
 */
function WideTease(props) {
  const {
    isRail = false,
    isLastArticle = false,
    hideBorder = false,
    content,
    HTag = 'h2',
  } = props;

  const {
    'show-nbc-sports-front': sportsFrontFF,
    'enable-sports-feed-updated-design': enableNewSportsFeedMobileDesign,
  } = useContext(FeatureFlagContext);
  const { isFluidWidthPage, isRailLayout } = useContext(LayoutContext);
  const pageVertical = useContext(VerticalContext);
  const unifiedItem = getUniformTeaseItem(content);

  const { item } = content;
  const {
    datePublished,
    description,
    headline,
    tease,
    unibrow,
    vertical,
    url,
  } = unifiedItem;

  const publisher = content?.computedValues?.teaseImage?.publisher?.name === 'nbcnews' ? 'NBC News' : content?.computedValues?.teaseImage?.publisher?.name;
  const sourceName = item?.source?.name || item?.source?.organization?.name;

  const displaySource = sourceToDisplay(sourceName);
  const shouldShowSourceName = useShouldShowSource(sourceName);
  const source = displaySource ? sourceName : publisher;

  const shouldOpenLinkInNewTab = sportsFrontFF ? shouldTargetBeBlankHelper(sourceName, item?.subType) : false;

  const showDek = getMetadata(POSSIBLE_METADATA.DEK, props);
  const showTimestamp = getMetadata(POSSIBLE_METADATA.TIMESTAMP, props);
  const showBrand = getMetadata(POSSIBLE_METADATA.BRAND, props);
  const showEyebrow = getMetadata(POSSIBLE_METADATA.EYEBROW, props);
  const showImage = getMetadata(POSSIBLE_METADATA.TEASE, props);
  const hasImage = showImage && !!tease;
  const showSave = getFeatureConfigForBrand(PKG_SAVE, pageVertical);

  return (
    <div
      className={classNames(
        'wide-tease-item__wrapper',
        'df',
        'flex-column',
        'flex-row-m',
        'flex-nowrap-m',
        {
          'wide-tease-item__wrapper--last-page': isLastArticle,
          'wide-tease-item__wrapper--no-border': hideBorder,
          'wide-tease-item__wrapper--full-width': !isRailLayout,
          'enable-new-sports-feed-mobile-design': enableNewSportsFeedMobileDesign,
        },
      )}
      data-contentid={content.id}
      data-testid="wide-tease"
      key={content.id}
    >
      {renderUnibrowAndDate({
        content,
        datePublished,
        showBrand,
        showEyebrow,
        showTimestamp,
        unibrow,
        vertical,
        isFluidWidthPage,
        enableNewSportsFeedMobileDesign,
      })}
      <div
        className={classNames(
          'wide-tease-item__info-wrapper',
          'flex-grow-1-m',
          {
            'wide-tease-item__info-wrapper--no-art': !hasImage && !isRail,
          },
        )}
        data-testid="wide-tease-info-wrapper"
      >
        {renderUnibrow({
          additionalClasses: 'dn db-m',
          content,
          dataAttributes: 'wide-tease-unibrow',
          showBrand,
          showEyebrow,
          unibrow,
          vertical,
          isFluidWidthPage,
          HTag,
        })}
        <Link
          target={shouldOpenLinkInNewTab ? '_blank' : ''}
          to={url}
        >
          <HTag
            className="wide-tease-item__headline"
            data-testid="wide-tease-headline"
          >
            {isFluidWidthPage && (
              <TypeIconInline type={content.type} taxonomy={content?.item?.taxonomy} />
            )}
            {headline}
          </HTag>
        </Link>
        <div className={classNames({ df: hasImage || isFluidWidthPage }, 'description-source-container')}>
          {showDek && (
            <div
              className="wide-tease-item__description"
              data-testid="wide-tease-dek"
            >
              {description}
              {enableNewSportsFeedMobileDesign && renderArticleImage({
                additionalClasses: 'mobile-article-image dt dn-m',
                content,
                isFluidWidthPage,
                showImage,
                showSave,
                sportsFrontFF,
                tease,
                url,
                vertical,
                shouldOpenLinkInNewTab,
              })}
            </div>
          )}
          {shouldShowSourceName && (
            <p className="wide-tease-sourceName">{source}</p>
          )}
          {!enableNewSportsFeedMobileDesign && renderArticleImage({
            additionalClasses: 'dt dn-m',
            content,
            isFluidWidthPage,
            showImage,
            showSave,
            sportsFrontFF,
            tease,
            url,
            vertical,
            shouldOpenLinkInNewTab,
          })}
        </div>
        <RecipeDetails
          type={content.type}
          cookTime={item?.cookTime}
          prepTime={item?.prepTime}
          servingSize={item?.servingSize}
          yields={item?.yield}
        />
      </div>

      {renderArticleImage({
        tease, showImage, additionalClasses: 'dn dt-m', content, url, isFluidWidthPage, showSave, sportsFrontFF, shouldOpenLinkInNewTab,
      })}
    </div>
  );
}

export default WideTease;
